/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background: var(--accent-main-background-color);
    background-color: var(--accent-main-background-color) !important;
    --alt-background-color-4 : #ffffff;
}


/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
// @import url('https://fonts.cdnfonts.com/css/advanced-dot-digital-7');
@font-face {
  font-family: 'Advanced Dot Digital-7';
  src: url('./assets/fonts/advanceddotdigital_7/AdvancedDotDigital-7.woff2') format('woff2'),
    url('./assets/fonts/advanceddotdigital_7/AdvancedDotDigital-7.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: Poppins;
    src: url('./assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

// @font-face {
//     font-family: Inter;
//     src: url('./assets/fonts/inter/Inter-Regular.otf') format("opentype");
// }

@font-face {
    font-family: Work Sans;
    src: url('./assets/fonts/work-sans/WorkSans-Regular.ttf') format("truetype");
}

@font-face {
    font-family: ZCOOL QingKe HuangYou;
    src: url('./assets/fonts/ZCOOL_QingKe_HuangYou/ZCOOLQingKeHuangYou-Regular.ttf') format("truetype");
}

app-header, app-header-mobile{
  position: sticky;
  top: 0;
  z-index: 99;
}

.fixed-bottom{
  z-index: 900;
}

.navigation-title {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  background-color: var(--accent-main-background-color) !important;
  color: var(--accent-main-text-color) !important;
  z-index: 9;

  .back-button {
    position: relative;
    min-width: 26.25px;
    cursor: pointer;
  }

  .title {
    position: relative;
    display: block;
    font-family: "Inter";
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    padding-right: 26.25px;
    width: 100%;
    text-align: center;
  }
}

.mat-bottom-sheet-container{
  border-radius: 20px 20px 0 0;
}

.footer{
  h1 {
      font-size: 1.8rem !important;
    }
}

.button-title {
    font-family: ZCOOL QingKe HuangYou;
    text-align: center;
    background: #1695F1;
    color: var(--primary-text-color);
    border-radius: 10px;
    padding: 10px 40px;
    font-size: 24px;
    display: table;
    margin: auto;
}

.page-title {
    font-family: ZCOOL QingKe HuangYou;
    text-transform: uppercase;
    color: var(--primary-text-color);
    font-size: 32px;
}

.page-inner {
  font-family: "Inter";

  .title{
      font-weight: 900;
    }
}

.inter-bold{
  font-weight: 700 !important;
}

.content-container{
  // width: calc(100% - 320px);
  // margin-left : 320px;
  flex: 1;
}

.sidebar-column {
  padding-right: 0 !important;
  position: relative;
  top: 0px;
  bottom: 0px;
}

.promotion-body{
  display: flex;
  position : relative;
  top: 0px;
  bottom: 0px;
  min-height: 700px;
  // margin-bottom: 20px;
}

.key-icon{
  margin-right: 10px;
}

.page-promotion .btn-detail{
  background: var(--button-primary-background-color) !important;
  color: var(--button-primary-text-color) !important;
  font-size: 1rem;
  height: 70%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.page-inner-container{
  border-radius: 0px 10px 10px 0px;
}

.copy-icon{
  margin-right : 10px;
}

.negative-amount {
  color: #FF0000 !important;
}
.positive-amount {
  color: #79A430 !important;
}

.pending-status-color{
  color: #FFFF00 !important;
}

.table-promotion th{
  border-bottom : none !important;
}

.table-promotion tbody td {
  vertical-align: middle;
}

.promotion-paginator{
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 599.98px) {
  #chat-widget-container,
  #livechat-eye-catcher {
    opacity: 0 !important;
    visibility: hidden !important;
    height: 0;
    width: 0;
    display: none !important;
    pointer-events: none !important;
  }
}

.table-promotion-container {
  overflow-x: auto;
  width: 100%;
}

.table-responsive-promotion{
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
}


.promotion-mobile-container{
  background-color: var(--accent-main-background-color) !important;
  color: var(--accent-main-text-color) !important;
  width: 100%;
  font-family: "Inter";
  min-height: 100%;

  .header-container{
    width: 100%;
    position: sticky;
    top: 0;
    background-color: var(--accent-main-background-color) !important;
    color: var(--accent-main-text-color) !important;
    z-index: 5;
    padding: 10px;

    .back-icon{
      position: absolute;
      top: 13px;
      bottom : 0px;
      left : 10px;
    }

    .title{
      font-weight: 900;
      font-size: 20px;
      text-align: center;
      color: var(--primary-text-color);
    }
  }

  .promotion-body{
    padding : 10px 10px 20px 10px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height : auto !important;

    .promotion-content{
      position: sticky;
    }

    .promotion-table{
      margin-top: 10px;
      height: calc(100vh - 250px);
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .filter-container{
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    background: var(--accent-primary-background-color);
    color: var(--accent-primary-text-color);

    .filter-icon{
      height: 10px;
      width: auto;
    }

    .close-button{
      margin-left:5px;
    }

    .filter-left{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 10px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);

      .filter-text{
        margin-left: 5px;

      }
    }

    .filter-right{
      display: flex;
      flex-direction: row;
      align-items: center;

      .filter-group{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1.5rem;
      }
    }
  }
}

.sidebar-menu{
  min-width: 52px;
}
@media(max-width: 800px) {
  .filter-blur{
    filter: blur(5px) brightness(0.8);
  }
}

@media (min-width: 801px) and (max-width: 1365.98px) {
  .promotion-body {
    padding: 0 ;
    margin-top: 20px;
  }

  .page-inner{
    padding: 0 !important;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  .promotion-body {
    padding: 0 ;
    margin-top: 20px;
  }

  .page-inner{
    padding: 0 !important;
  }

  .btn-icon{
    width: auto !important;
    margin-right: 20px !important;
    min-width: auto !important;
  }

  .promotion-inner{
    width: calc(100% - 52px);
    margin-left: 52px;
  }

  .sidebar-column{
    position: absolute !important;
    z-index:2 !important;
  }
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: var(--primary-text-color);
  --mdc-snackbar-supporting-text-color: var(--primary-text-color);
  border-radius: 10px;
  &.notification-error {
    --mdc-snackbar-container-color: #FF0000 !important;
  }

  &.notification-success {
    --mdc-snackbar-container-color: rgb(14, 128, 14) !important;
  }

  &.notification-default {
    --mdc-snackbar-container-color: #484848 !important;
  }

  .mdc-snackbar__surface {
    min-width: auto !important;
    border-radius: 15px !important;
    padding: 0px !important;
  }
}

.promotion-inner{
  border-top-right-radius  : 10px;
}

.footer{
  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
      margin-bottom: 0;
  }
  p{
    margin: 5px;
  }
  .mat-expansion-panel-body {
    padding: 20px 10px;
  }
  .mobile{
    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
      margin-bottom: 0;
    }
    h1, .h1{
      font-size: 18px !important;
    }
    h2, .h2{
      font-size: 17px !important;
    }
    h3, .h3{
      font-size: 16px !important;
    }
    h4, .h4{
      font-size: 14px !important;
    }
    h5, .h5, h6, .h6 {
      font-size: 14px !important;
    }
    p{
      margin: 5px;
      font-size: 13px;
    }

    .mat-expansion-panel-body{
      padding: 20px 10px;
    }
  }
}
