/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  outline: 0;
  box-shadow: none !important;
}

.form-control.transparent:disabled {
  background-color: transparent !important;
}

.btn-check.transparent:checked+.btn,
.btn.transparent:hover,
:not(.btn-check)+.btn:active,
.btn.transparent:first-child:active,
.btn.transparent.active,
.btn.transparent.show {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.mat-bottom-sheet-container {
  padding-top: 0 !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/inter/Inter-Regular.otf") format("opentype");
}

@font-face {
  font-family: Work Sans;
  src: url("./assets/fonts/work-sans/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: ZCOOL QingKe HuangYou;
  src: url("./assets/fonts/ZCOOL_QingKe_HuangYou/ZCOOLQingKeHuangYou-Regular.ttf") format("truetype");
}



.button-title {
  font-family: ZCOOL QingKe HuangYou;
  text-align: center;
  background: #1695f1;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 40px;
  font-size: 24px;
  display: table;
  margin: auto;
}

.page-title {
  font-family: ZCOOL QingKe HuangYou;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 32px;
}

.modal-backdrop {
  background-color: #051a47;
  --bs-backdrop-opacity: 0.8;
}

.modal-header {
  font-family: ZCOOL QingKe HuangYou;
  background-color: #242d6f;
  color: #fdce21;
  border-bottom: none;
  box-shadow: solid 1px;
}

.modal-header .btn-close {
  opacity: 1;
  color: #1695f1 !important;
  background-color: #ffffff !important;
  background-size: 10px;
  padding: 2px;
  border-radius: 50%;
  margin-right: 5px;
  border: none;
}

.modal-body {
  background: rgb(23, 32, 91);
  background: linear-gradient(90deg,
      rgba(23, 32, 91, 1) 0%,
      rgba(54, 64, 149, 1) 50%,
      rgba(22, 27, 82, 1) 100%);
}

.stepper {
  display: flex;
  justify-content: space-between;
}

.stepper .stepper-item {
  text-align: center;
  color: #8e8e8e;
  white-space: nowrap;
}

.stepper .stepper-item.active {
  color: #fdce21;
}

.stepper .stepper-item .stepper-number {
  display: inline-block;
  background: #b3b3b3;
  border-radius: 50%;
  padding: 5px 13px;
  color: #ffffff;
  margin-bottom: 10px;
  position: relative;
}

.stepper .stepper-line {
  position: relative;
  top: 18px;
  display: block;
  width: 100%;
  height: 1px;
  background: #b3b3b3;
}

.stepper .stepper-item.active .stepper-number,
.stepper .stepper-line.active {
  background: #fdce21;
}

.page-inner {
  font-family: ZCOOL QingKe HuangYou;
  background: rgb(23, 32, 91);
  background: linear-gradient(90deg,
      rgba(23, 32, 91, 1) 0%,
      rgba(54, 64, 149, 1) 50%,
      rgba(22, 27, 82, 1) 100%);
  padding: 20px;
}

.page-inner-container {
  border-left: solid 1px #090b19;
  padding: 0 20px;
  min-height: calc(100vh - 288px);
}

.page-inner .title {
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
}

.page-content {
  margin: 0;
}

.button-title {
  font-family: ZCOOL QingKe HuangYou;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  display: table;
  margin: auto;
  margin-bottom: 45px;
  background: #1695f1;
  padding: 10px 40px;
}

.bg-blue-linear {
  background: rgb(23, 32, 91);
  background: linear-gradient(90deg,
      rgba(23, 32, 91, 1) 0%,
      rgba(54, 64, 149, 1) 50%,
      rgba(22, 27, 82, 1) 100%);
}

.form-control:disabled {
  background: #c5c2c2 !important;
  color: #5e6062 !important;
}

.password {
  position: relative;
}

.password input {
  padding-right: 40px;
}

.password .ng-fa-icon {
  position: absolute;
  top: 6px;
  right: 20px;
  cursor: pointer;
}

.btn-blue,
.btn-blue:hover {
  background: #131e96;
  color: #ebf3ea;
  padding: 10px 40px;
}

.btn-black,
.btn-black:hover {
  background: #2a2b2b;
  color: #ebf3ea;
  padding: 10px 40px;
}

.btn-block {
  width: 100%;
}

.table {
  font-family: Poppins;
}

.table thead th {
  background: #323d8e;
  color: #ffffff;
  font-weight: normal;
  padding: 20px;
}

.table tbody td {
  padding: 20px;
}

.table .badge {
  padding: 8px 20px;
  border-radius: 20px;
  background: rgb(114, 181, 244, 0.3);
  color: #3542b9;
}

.table caption {
  background: #ffffff;
  color: #969b9f;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table caption b {
  color: #000000;
}

.promotion-inner {
  border-left: none !important;
  background-color: #081928;
}

.promotion-container {
  padding-left: 0px !important;
}

.table-promotion {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-promotion th,
.table-promotion td {
  padding: 8px 16px;
  text-align: left;
  color: white;
}

.table-promotion thead th {
  background-color: #060E15;
}

.table-promotion tbody td {
  background-color: #081928;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.page-inner .btn-back {
  background-color: #081928;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  margin: 5px;
  width: 100%;
  opacity: 50%;
}

.navigation-button {
  background-color: #1A5684 !important;
  border: none !important;
}

.pointer-off {
  pointer-events: none;
}

.page-promotion {
  .btn-detail {
    background: linear-gradient(180deg, #84B332 0%, #668A28 100%) !important;
    border: none !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}